import { Cartesian3, Viewer as CesiumViewer } from "cesium";
import React, { Component, createRef } from "react";
import { CesiumComponentRef, Entity, ModelGraphics, Viewer } from "resium";
import "./App.css";
// @ts-ignore
import BuildingModel from "./assest/building.gltf";
import ControlPanel from "./components/ControlPanel";

declare global {
  interface Window {
    Cesium: any;
    graphicManager: any;
  }
}

export default class App extends Component {
  private viewer = createRef<CesiumComponentRef<CesiumViewer>>();

  render() {
    return (
      <>
        <Viewer
          full
          baseLayerPicker={false}
          navigationHelpButton={false}
          projectionPicker={false}
          sceneModePicker={false}
          homeButton={false}
          geocoder={false}
          fullscreenButton={false}
          infoBox={false}
          selectionIndicator={false}
          ref={this.viewer}
        >
          <Entity position={Cartesian3.fromDegrees(101.9758, 4.2105)}>
            <ModelGraphics uri={BuildingModel} minimumPixelSize={128} />
          </Entity>
          <Entity position={Cartesian3.fromDegrees(101.9758, 4.2125)}>
            <ModelGraphics uri={BuildingModel} minimumPixelSize={128} />
          </Entity>
        </Viewer>
        {this.viewer && (
          <ControlPanel
            viewer={this.viewer}
            geodesic={new window.Cesium.EllipsoidGeodesic()}
          />
        )}
      </>
    );
  }
}
