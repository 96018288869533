import React, { Component } from "react";
import Icon, { IconType } from "../assest/Icon";

interface Props {
  viewer: any;
  geodesic: any;
}

export default class ControlPanel extends Component<Props> {
  state = {
    helpVisible: true,
    mobileView: false,
    positions: [],
    distanceLabel: "",
    verticalLabel: "",
    horizontalLabel: "",
    point1GP: { longitude: 0, latitude: 0, height: 0 },
    point2GP: { longitude: 0, latitude: 0, height: 0 },
  };

  componentDidMount = () => {
    this.handleCheckMobileView();
    const viewer = this.props.viewer.current.cesiumElement;

    let point1: any;
    let point2: any;
    const linePointColor = window.Cesium.Color.RED;
    const boundingSphere = new window.Cesium.BoundingSphere(
      window.Cesium.Cartesian3.fromDegrees(101.9758, 4.2125),
      150
    );

    viewer.camera.viewBoundingSphere(
      boundingSphere,
      new window.Cesium.HeadingPitchRange(
        0.5,
        -0.2,
        boundingSphere.radius * 4.0
      )
    );
    viewer.camera.lookAtTransform(window.Cesium.Matrix4.IDENTITY);

    let points = viewer.scene.primitives.add(
      new window.Cesium.PointPrimitiveCollection()
    );
    let polylines = viewer.scene.primitives.add(
      new window.Cesium.PolylineCollection()
    );

    const handler = new window.Cesium.ScreenSpaceEventHandler(viewer.canvas);
    handler.setInputAction(async (click: { position: any }) => {
      if (viewer.scene.mode !== window.Cesium.SceneMode.MORPHING) {
        let pickedObject = viewer.scene.pick(click.position);
        if (
          viewer.scene.pickPositionSupported &&
          window.Cesium.defined(pickedObject)
        ) {
          let cartesian = viewer.scene.pickPosition(click.position);
          if (window.Cesium.defined(cartesian)) {
            if (points.length === 2) {
              points.removeAll();
              polylines.removeAll();
              viewer.entities.remove(this.state.distanceLabel);
              viewer.entities.remove(this.state.horizontalLabel);
              viewer.entities.remove(this.state.verticalLabel);
              this.setState({
                distanceLabel: "",
                horizontalLabel: "",
                verticalLabel: "",
              });
            }
            if (points.length === 0) {
              point1 = points.add({
                position: new window.Cesium.Cartesian3(
                  cartesian.x,
                  cartesian.y,
                  cartesian.z
                ),
                color: linePointColor,
              });
            } else if (points.length === 1) {
              point2 = points.add({
                position: new window.Cesium.Cartesian3(
                  cartesian.x,
                  cartesian.y,
                  cartesian.z
                ),
                color: linePointColor,
              });
              this.setState({
                point1GP: window.Cesium.Cartographic.fromCartesian(
                  point1.position
                ),
                point2GP: window.Cesium.Cartographic.fromCartesian(
                  point2.position
                ),
              });

              let pl1Positions = [
                new window.Cesium.Cartesian3.fromRadians(
                  this.state.point1GP.longitude,
                  this.state.point1GP.latitude,
                  this.state.point1GP.height
                ),
                new window.Cesium.Cartesian3.fromRadians(
                  this.state.point2GP.longitude,
                  this.state.point2GP.latitude,
                  this.state.point2GP.height
                ),
              ];
              let pl2Positions = [
                new window.Cesium.Cartesian3.fromRadians(
                  this.state.point2GP.longitude,
                  this.state.point2GP.latitude,
                  this.state.point2GP.height
                ),
                new window.Cesium.Cartesian3.fromRadians(
                  this.state.point2GP.longitude,
                  this.state.point2GP.latitude,
                  this.state.point1GP.height
                ),
              ];
              let pl3Positions = [
                new window.Cesium.Cartesian3.fromRadians(
                  this.state.point1GP.longitude,
                  this.state.point1GP.latitude,
                  this.state.point1GP.height
                ),
                new window.Cesium.Cartesian3.fromRadians(
                  this.state.point2GP.longitude,
                  this.state.point2GP.latitude,
                  this.state.point1GP.height
                ),
              ];

              polylines.add({
                show: true,
                positions: pl1Positions,
                width: 1,
                material: new window.Cesium.Material({
                  fabric: {
                    type: "Color",
                    uniforms: {
                      color: linePointColor,
                    },
                  },
                }),
              });
              polylines.add({
                show: true,
                positions: pl2Positions,
                width: 1,
                material: new window.Cesium.Material({
                  fabric: {
                    type: "PolylineDash",
                    uniforms: {
                      color: linePointColor,
                    },
                  },
                }),
              });
              polylines.add({
                show: true,
                positions: pl3Positions,
                width: 1,
                material: new window.Cesium.Material({
                  fabric: {
                    type: "PolylineDash",
                    uniforms: {
                      color: linePointColor,
                    },
                  },
                }),
              });
              let labelZ;
              if (this.state.point2GP.height >= this.state.point1GP.height) {
                labelZ =
                  this.state.point1GP.height +
                  (this.state.point2GP.height - this.state.point1GP.height) /
                    2.0;
              } else {
                labelZ =
                  this.state.point2GP.height +
                  (this.state.point1GP.height - this.state.point2GP.height) /
                    2.0;
              }

              this.addDistanceLabel(point1, point2, labelZ);
            }
          }
        }
      }
    }, window.Cesium.ScreenSpaceEventType.LEFT_CLICK);
  };

  handleCheckMobileView = () => {
    let mobileView = false;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      mobileView = true;
    }
    this.setState({
      mobileView,
    });
  };

  handleChangeHelpVisible = () => {
    const visible = !this.state.helpVisible;
    this.setState({
      helpVisible: visible,
    });
  };

  addDistanceLabel = (point1: any, point2: any, height: any) => {
    const viewer = this.props.viewer.current.cesiumElement;

    point1.cartographic = window.Cesium.Ellipsoid.WGS84.cartesianToCartographic(
      point1.position
    );
    point2.cartographic = window.Cesium.Ellipsoid.WGS84.cartesianToCartographic(
      point2.position
    );
    point1.longitude = parseFloat(
      window.Cesium.Math.toDegrees(point1.position.x)
    );
    point1.latitude = parseFloat(
      window.Cesium.Math.toDegrees(point1.position.y)
    );
    point2.longitude = parseFloat(
      window.Cesium.Math.toDegrees(point2.position.x)
    );
    point2.latitude = parseFloat(
      window.Cesium.Math.toDegrees(point2.position.y)
    );
    const horizontalLabel = this.getHorizontalDistanceString(point1, point2);
    const distanceLabel = this.getDistanceString(point1, point2);
    const verticalDistanceLabel = this.getVerticalDistanceString();

    this.setState({
      horizontalLabel: viewer.entities.add({
        position: this.getMidpoint(point1, point2, this.state.point1GP.height),
        label: {
          font: "14px monospace",
          showBackground: true,
          horizontalOrigin: window.Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: window.Cesium.VerticalOrigin.CENTER,
          pixelOffset: new window.Cesium.Cartesian2(0, 0),
          eyeOffset: new window.Cesium.Cartesian3(0, 0, -50),
          fillColor: window.Cesium.Color.WHITE,
          text: horizontalLabel,
        },
      }),
      distanceLabel: viewer.entities.add({
        position: this.getMidpoint(point1, point2, height),
        label: {
          font: "14px monospace",
          showBackground: true,
          horizontalOrigin: window.Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: window.Cesium.VerticalOrigin.CENTER,
          pixelOffset: new window.Cesium.Cartesian2(0, 0),
          eyeOffset: new window.Cesium.Cartesian3(0, 0, -50),
          fillColor: window.Cesium.Color.WHITE,
          text: distanceLabel,
        },
      }),
      verticalLabel: viewer.entities.add({
        position: this.getMidpoint(point2, point2, height),
        label: {
          font: "14px monospace",
          showBackground: true,
          horizontalOrigin: window.Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: window.Cesium.VerticalOrigin.CENTER,
          pixelOffset: new window.Cesium.Cartesian2(0, 0),
          eyeOffset: new window.Cesium.Cartesian3(0, 0, -50),
          fillColor: window.Cesium.Color.WHITE,
          text: verticalDistanceLabel,
        },
      }),
    });
  };

  getHorizontalDistanceString = (
    point1: { cartographic: any },
    point2: { cartographic: any }
  ) => {
    const geodesic = this.props.geodesic;
    geodesic.setEndPoints(point1.cartographic, point2.cartographic);
    let meters = geodesic.surfaceDistance.toFixed(2);
    if (meters >= 1000) {
      return (meters / 1000).toFixed(1) + " км";
    }
    return meters + " м";
  };

  getVerticalDistanceString = () => {
    let heights = [this.state.point1GP.height, this.state.point2GP.height];
    let meters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
    if (meters >= 1000) {
      return (meters / 1000).toFixed(1) + " км";
    }

    return meters.toFixed(2) + " м";
  };

  getDistanceString = (point1: any, point2: any) => {
    const geodesic = this.props.geodesic;

    geodesic.setEndPoints(point1.cartographic, point2.cartographic);
    let horizontalMeters = geodesic.surfaceDistance.toFixed(2);
    let heights = [this.state.point1GP.height, this.state.point2GP.height];
    let verticalMeters =
      Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
    let meters = Math.pow(
      Math.pow(horizontalMeters, 2) + Math.pow(verticalMeters, 2),
      0.5
    );

    if (meters >= 1000) {
      return (meters / 1000).toFixed(1) + " км";
    }
    return meters.toFixed(2) + " м";
  };

  getMidpoint = (
    point1: { cartographic: any },
    point2: { cartographic: any },
    height: any
  ) => {
    const geodesic = this.props.geodesic;
    let scratch = new window.Cesium.Cartographic();
    geodesic.setEndPoints(point1.cartographic, point2.cartographic);
    let midpointCartographic = geodesic.interpolateUsingFraction(0.5, scratch);
    return window.Cesium.Cartesian3.fromRadians(
      midpointCartographic.longitude,
      midpointCartographic.latitude,
      height
    );
  };

  render() {
    const mobileView = this.state.mobileView;
    return (
      <>
        <div
          style={{ display: "block", position: "absolute", top: 5, right: 5 }}
        >
          <div
            style={{
              display: "inline-block",
              position: "relative",
              cursor: "pointer",
              background: "#111827",
              borderRadius: 5,
              width: 32,
              height: 32,
            }}
            onClick={this.handleChangeHelpVisible}
          >
            <Icon type={IconType.Help} />
          </div>
        </div>
        {this.state.helpVisible && (
          <div>
            <div
              style={{
                position: "absolute",
                top: 40,
                right: 5,
                width: 250,
                borderRadius: 10,
                backgroundColor: "#111827",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td>
                      {mobileView ? (
                        <Icon type={IconType.TouchDrag} />
                      ) : (
                        <Icon type={IconType.Left} />
                      )}
                    </td>
                    <td>
                      <p
                        style={{
                          fontWeight: "bold",
                          margin: 0,
                          color: "#0EA5E9",
                        }}
                      >
                        Pan view
                      </p>
                      <p style={{ borderSpacing: 2, margin: 0, color: "#fff" }}>
                        {mobileView ? "One finger drag" : "Left click + drag"}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mobileView ? (
                        <Icon type={IconType.Touch} />
                      ) : (
                        <Icon type={IconType.Left} />
                      )}
                    </td>
                    <td>
                      <p
                        style={{
                          fontWeight: "bold",
                          margin: 0,
                          color: "#0EA5E9",
                        }}
                      >
                        Measure
                      </p>
                      <p style={{ borderSpacing: 2, margin: 0, color: "#fff" }}>
                        {mobileView
                          ? "Tap the model"
                          : "Left click on the model"}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {mobileView ? (
                        <Icon type={IconType.ZoomView} />
                      ) : (
                        <Icon type={IconType.Right} />
                      )}
                    </td>
                    <td>
                      <p
                        style={{
                          fontWeight: "bold",
                          margin: 0,
                          color: "#0EA5E9",
                        }}
                      >
                        Zoom view
                      </p>
                      <p style={{ borderSpacing: 2, margin: 0, color: "#fff" }}>
                        {mobileView
                          ? "Two Finger Pinch"
                          : "Right click + drag, or Mouse wheel scroll"}
                      </p>
                    </td>
                  </tr>
                  {mobileView && (
                    <tr>
                      <td>
                        <Icon type={IconType.TiltView} />
                      </td>
                      <td>
                        <p
                          style={{
                            fontWeight: "bold",
                            margin: 0,
                            color: "#0EA5E9",
                          }}
                        >
                          Tilt View
                        </p>
                        <p
                          style={{ borderSpacing: 2, margin: 0, color: "#fff" }}
                        >
                          Two finger drag, same direction
                        </p>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      {mobileView ? (
                        <Icon type={IconType.RotateView} />
                      ) : (
                        <Icon type={IconType.ScrollWheel} />
                      )}
                    </td>
                    <td>
                      <p
                        style={{
                          fontWeight: "bold",
                          margin: 0,
                          color: "#0EA5E9",
                        }}
                      >
                        Rotate view
                      </p>
                      <p style={{ borderSpacing: 2, margin: 0, color: "#fff" }}>
                        {mobileView
                          ? "Two finger drag, opposite direction"
                          : "Middle click + drag, or CTRL + Left/Right click + drag"}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </>
    );
  }
}
